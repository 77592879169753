import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout';

import img1 from '../images/20141025_094748.jpg';
import img2 from '../images/20141025_105506.jpg';
import img3 from '../images/20141025_110150.jpg';
import img4 from '../images/20141025_110712.jpg';
import img5 from '../images/20141025_110807.jpg';
import img6 from '../images/20141025_132204.jpg';
import img7 from '../images/20141025_132251.jpg';
import img8 from '../images/20141025_132514.jpg';
import img9 from '../images/20141025_143423.jpg';

import styles from './page.module.scss';

const SecondPage = () => (
  <Layout>
    <h1>Our projects</h1>
    <p>
      The Ebola crisis which struck Liberia in 2014 has refocused much of the country’s 
      development efforts into fighting and ultimately eradicating this terrible disease. 
      Check One Foundation is taking part in this fight through our Ebola project.
    </p>
    <p>
      The Ebola virus disease (EVD) is continuing to spread in especially communities 
      without Ebola response team in Liberia through human-to-human transmission. 
      Since the outbreak the virus has proven to be difficult to contain as a result 
      of the following factors:
      <ul>
        <li>“Strong cultural practices and traditional beliefs” in Liberia mean that traditional funeral practices, fear, and misinformation make the outbreak difficult to contain.</li>
        <li>Weak health-care infrastructures, community denial and resistance.</li>
        <li>Local community people have posed fear, misinformation and disbelieve in the existence of the virus.</li>
      </ul>
    </p>
    <p>
      Education, awareness and distributions of Ebola prevention materials among local community 
      people are the most powerful weapon to fight the virus in a society of approximately 3.7 
      million people where 80% of the population is illiterate.
      Therefore in an effort to complement the effort of Government and other humanitarian 
      agencies working to contain the virus,
    </p>
    <p>
      The Check One Foundation Ebola project focuses on distribution of Ebola preventive 
      materials and messages on the existence and prevention of the Ebola virus especially 
      among citizens of all ages in Swankamore community, Monrovia. The action will help break 
      the barriers of misconceptions among local community about the existence of the virus 
      and prevention methods. This will also give already infected victims the chance and 
      confidence to report cases after contracting the virus.
    </p>
    <div className={styles.pageGallery}>
      <img className={styles.galleryImage} src={img1} />
      <img className={styles.galleryImage} src={img2} />
      <img className={styles.galleryImage} src={img3} />
      <img className={styles.galleryImage} src={img4} />
      <img className={styles.galleryImage} src={img5} />
      <img className={styles.galleryImage} src={img6} />
      <img className={styles.galleryImage} src={img7} />
      <img className={styles.galleryImage} src={img8} />
      <img className={styles.galleryImage} src={img9} />
    </div>
</Layout>
)

export default SecondPage
