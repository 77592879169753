import React from 'react'

import styles from './Footer.module.scss';

const Footer = () => (
  <div className={styles.footerContainer}>
    Copyright © 2018 Check One Foundation 
  </div>
)

export default Footer
