import React from 'react'
import { Link } from 'gatsby';
import classNames from 'classnames';

import styles from './Header.module.scss';
import icon from '../images/favicon.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {menuToggle: false};

    this.toggleMenu = this.toggleMenu.bind(this);

  }

  toggleMenu() {
    this.setState(state => ({
      menuToggle: !state.menuToggle
    }));
  }

  render() {

    const { image } = this.props;
    const { menuToggle } = this.state;

    return (
      <div className={styles.headerContainer}>
        <button type='button' onClick={this.toggleMenu}><img src={icon} /></button>
        <div className={classNames([styles.menuContainer, menuToggle ? styles.show : styles.hide])}>
          <Link className={styles.menuLink} to='/'>Home</Link>
          <Link className={styles.menuLink} to='/projects'>Our projects</Link>
          <Link className={styles.menuLink} to='/who-we-are'>Who we are</Link>
          <Link className={styles.menuLink} to='/contact'>Contact</Link>
        </div>
        <img src={image} alt='header' />
      </div>
    )
  }
}

export default Header
