import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header';
import Footer from './Footer';

import headerimage from '../images/check-one-banner-photo.jpg';

import styles from './Layout.module.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Working with local communities in Liberia' },
            { name: 'keywords', content: 'check, one, liberia, foundation, community' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className={styles.layoutContainer}>
          <Header image={headerimage} />
          <div className={styles.pageContainer}>
            {children}
            <Footer />
          </div>
      </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
